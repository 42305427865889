<template>
  <div class="personal-mob">
    <mob-header :interaction="false" :logout="true"></mob-header>
    <mob-user-center
      :headPhoto="userInfo.wxheadimg"
      :name="userInfo.username"
      :phone="userInfo.phone"
      :order="subscribeCount"
      :logining="logining"
      :collect="collectCount"
    ></mob-user-center>
    <mob-footer-bar :active="3"></mob-footer-bar>
  </div>
</template>

<script>
import MobHeader from "../../components/mob-header.vue";
import MobFooterBar from "../../components/mob-footer-bar.vue";
import MobUserCenter from "../../components/mob-user-center.vue";
import { userInfo, wechatConfig } from "../../api/user";
import wechat from "../../utils/wxShare";

import { getToken } from "../../utils/auth";
export default {
  components: {
    MobHeader,
    MobFooterBar,
    MobUserCenter,
  },
  data() {
    return {
      userInfo: {},
      logining: false,
      collectCount: 0,
      subscribeCount: 0,
    };
  },
  created() {
    this.getUserInfo();
    this.getWechatConfig();
  },
  methods: {
    getWechatConfig() {
      wechatConfig({
        url: location.href,
      }).then((res) => {
        if (res.code == 0) {
          this.wxConfigData = res.data;
          wechat.share(
            this.wxConfigData,
            "因美纳在线讲堂",
            "因美纳在线讲堂",
            this.$store.state.url,
            location.href
          );
        }
      });
    },
    getUserInfo() {
      if (getToken()) {
        userInfo().then((res) => {
          if (res.code == 0) {
            this.userInfo = res.data;
            this.collectCount = res.collectCount;
            this.subscribeCount = res.subscribeCount;
            this.logining = true;
            sessionStorage.setItem("userInfo", JSON.stringify(res.data));
          } else {
            this.logining = false;
            // this.$router.replace({ path: "/login" });
          }
        });
      } else {
        // this.$router.replace({ path: "/login" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.personal-mob {
  height: 100%;
  background: url("../../assets/h5bg.png") 0 0 no-repeat;
  background-size: 100% auto;
}
</style>
