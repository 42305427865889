<template>
  <div class="page-view">
    <personal-mob v-if="isMobile"></personal-mob>
    <personal-pc v-else></personal-pc>
  </div>
</template>

<script>
import PersonalPc from "./personal-pc.vue";
import PersonalMob from "./personal-mob.vue";
export default {
  components: {
    PersonalPc,
    PersonalMob,
  },
  metaInfo: {
    title: "因美纳讲堂 | 个人中心",
  },
  data() {
    return {
      isMobile: this.$isMobile(),
    };
  },
};
</script>
