<template>
  <div class="user-center">
    <div class="user-box">
      <div class="photo" @click="toLogin()">
        <img v-if="headPhoto" :src="headPhoto" alt="" />
        <img v-else src="../assets/default.png" alt="" />
        <!-- <router-link to="/login" replace>
          <img class="default" v-if="!logining" src="../assets/default.png" alt="" />
        </router-link> -->
      </div>
      <div class="modify" v-if="logining" @click="toChange()">
        <img src="../assets/updatePassword.png" alt="" />
        <span>密码修改</span>
      </div>
      <div class="user-info">
        <span @click="toLogin()">{{ logining ? name : "点击登录" }}</span
        ><br />
        <span v-if="logining">{{
          phone.substr(0, 3) + "****" + phone.substr(7, 4)
        }}</span>
      </div>
      <div class="user-plate">
        <div class="plate-item" @click="toInformation()">
          <div class="left-box">
            <img src="../assets/info.png" alt="" />
          </div>
          <span>基本信息</span>
          <div class="right-box">
            <img src="../assets/right.png" alt="" />
          </div>
        </div>
        <div class="plate-item" @click="toHistory()">
          <div class="left-box">
            <img src="../assets/history.png" alt="" />
          </div>
          <span>浏览历史</span>
          <div class="right-box">
            <img src="../assets/right.png" alt="" />
          </div>
        </div>
        <div class="plate-item" @click="toAppointment()">
          <div class="left-box">
            <img src="../assets/myAppointment.png" alt="" />
          </div>
          <span>我的预约</span>
          <span v-if="logining"
            >共有<strong>{{ order }}</strong
            >条预约</span
          >
          <div class="right-box">
            <img src="../assets/right.png" alt="" />
          </div>
        </div>
        <div class="plate-item" @click="toCollect()">
          <div class="left-box">
            <img src="../assets/myCollect.png" alt="" />
          </div>
          <span>我的收藏</span>
          <span v-if="logining"
            >共有<strong>{{ collect }}</strong
            >条收藏</span
          >
          <div class="right-box">
            <img src="../assets/right.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    headPhoto: {
      type: String,
      require: true,
    },
    name: {
      type: String,
      require: true,
      default: "",
    },
    phone: {
      type: String,
      require: true,
    },
    order: {
      type: [String, Number],
      require: true,
      default: 0,
    },
    collect: {
      type: [String, Number],
      require: true,
      default: 0,
    },
    logining: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headImgStatus: true,
    };
  },
  methods: {
    toChange() {
      this.$router.push({ path: "/personal/change" });
    },
    toInformation() {
      this.$router.push({ path: "/personal/information" });
    },
    toHistory() {
      this.$router.push({ path: "/personal/history" });
    },
    toAppointment() {
      this.$router.push({ path: "/personal/appointment" });
    },
    toCollect() {
      this.$router.push({ path: "/personal/collect" });
    },
    toLogin() {
      if (!this.logining) {
        this.$router.push({ path: "/login" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.user-center {
  width: 3.55rem;
  height: 3.62rem;
  background-color: #fff;
  margin: auto;
  position: absolute;
  top: 1.05rem;
  left: 50%;
  transform: translateX(-50%);
  .photo {
    width: 0.88rem;
    height: 0.88rem;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
    background-color: #ececec;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  // .photo::after {
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   content: "";
  //   display: block;
  //   top: 0;
  //   left: 0;
  //   background: url("../assets/default.png") 0 0 no-repeat;
  //   background-size: 100%;
  // }
  .modify {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 0.15rem;
    right: 0.15rem;
    // margin-right: 0.15rem;
    // margin-top: 0.15rem;
    font-size: 0.14rem;
    color: #999;
    img {
      width: 0.17rem;
      height: 0.17rem;
      margin-right: 0.08rem;
    }
  }
  .user-info {
    text-align: center;
    margin-top: 0.5rem;
    margin-bottom: 0.33rem;
    span:nth-of-type(1) {
      color: #333;
      font-size: 0.14rem;
    }
    span {
      font-size: 0.12rem;
      color: #999;
    }
  }
  .user-plate {
    padding: 0 0.21rem;
    border-top: 1px solid #e6e2d7;
    .plate-item {
      height: 0.5rem;
      border-bottom: 1px solid #e6e2d7;
      display: flex;
      // align-items: center;
      box-sizing: border-box;
      padding-top: 0.18rem;
      // justify-content: space-between;
      span:nth-of-type(1) {
        margin-right: auto;
        margin-left: 0.16rem;
        font-size: 0.14rem;
      }
      span:nth-of-type(2) {
        color: #999;
        margin-right: 0.15rem;
        font-size: 0.14rem;
        line-height: 0.16rem;
        strong {
          font-size: 0.16rem;
          color: #4ab67c;
        }
      }
      img {
        width: 0.17rem;
        height: 0.17rem;
      }
      .right-box {
        img {
          width: 0.14rem;
        }
      }
    }
    .plate-item:nth-last-of-type(1) {
      border-bottom: none;
    }
  }
}
</style>
