<template>
  <div class="pc-header">
    <div class="main">
      <div class="logo">
        <img src="../assets/logo.png" alt="" />
      </div>
      <div class="right">
        <div class="nav">
          <router-link to="/home">
            <span>首页</span>
          </router-link>
          <router-link to="/live">
            <span>直播</span>
          </router-link>
          <router-link to="/review">
            <span>回顾</span>
          </router-link>
          <router-link to="/personal">
            <span>个人中心</span>
          </router-link>

          <!-- <span @click="toLive()">直播</span>
          <span @click="toReview()">回顾</span>
          <span @click="toPersonal()">个人中心</span> -->
        </div>
        <div class="personal">
          <!-- <i @click="qrcodeShow = true">
            <img src="../assets/enquiry.png" alt="" />
          </i>
          <span class="bar"></span> -->

          <i
            ><router-link to="/search">
              <img src="../assets/search.png" alt=""
            /></router-link>
          </i>

          <router-link v-if="status === 'login'" to="/login">
            <button>登录</button>
          </router-link>
          <div v-else-if="status === 'info'" class="info">
            <span>{{ userInfo.username }}</span>
            <router-link to="/personal">
              <div class="header-img">
                <img
                  v-if="userInfo.wxheadimg"
                  :src="userInfo.wxheadimg"
                  alt=""
                />
                <img v-else src="../assets/default.png" alt="" />
              </div>
            </router-link>
          </div>
          <button v-else-if="status === 'logout'" @click="userLogout()">
            退出
          </button>
        </div>
      </div>
    </div>
    <qrcode-popup :isShow="qrcodeShow" @close="closeQrcode"></qrcode-popup>
  </div>
</template>

<script>
import QrcodePopup from "../components/qrcode-popup.vue";
import { userInfo, logout } from "../api/user";
import { getToken, removeToken } from "../utils/auth";
export default {
  components: {
    QrcodePopup,
  },
  props: {
    logout: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      qrcodeShow: false,
      userInfo: {},
      status: "login",
    };
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    toHome() {
      if (location.pathname != "/home") {
        this.$router.push({ path: "/home" });
      }
    },
    toLive() {
      if (location.pathname != "/live") {
        this.$router.push({ path: "/live" });
      }
    },
    toReview() {
      if (location.pathname != "/review") {
        this.$router.push({ path: "/review" });
      }
    },
    toPersonal() {
      this.$router.push({ path: "/personal" });
    },
    toSearch() {
      if (location.pathname != "/search") {
        this.$router.push({ path: "/search" });
      }
    },
    toLogin() {
      if (location.pathname != "/login") {
        this.$router.push({ path: "/login" });
      }
    },
    closeQrcode(val) {
      this.qrcodeShow = val;
    },
    // 查询用户信息
    getUserInfo() {
      if (getToken()) {
        userInfo().then((res) => {
          if (res.code == 0) {
            this.userInfo = res.data;
            if (this.logout) {
              this.status = "logout";
            } else {
              this.status = "info";
            }

            sessionStorage.setItem("userInfo", JSON.stringify(res.data));
          } else {
            this.status = "login";
          }
        });
      } else {
        this.status = "login";
      }
    },
    // 退出登录
    userLogout() {
      logout({
        id: JSON.parse(sessionStorage.getItem("userInfo")).id,
        token: null,
      }).then((res) => {
        if (res.code == 0) {
          this.status = "login";
          // setToken(null);
          removeToken();
          this.$router.replace({ path: "/home" });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
  color: #fff;
}
.pc-header {
  width: 100%;
  height: 80px;
  background-color: rgba($color: #000000, $alpha: 0);
  border-bottom: 1px solid rgba($color: #fff, $alpha: 0.3);
  .main {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .logo {
      // cursor: pointer;
      width: 195px;
      height: 100%;
      display: flex;
      align-items: center;
      img {
        width: 100%;
        display: block;
      }
    }
    .right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 500px;
      height: 100%;
      color: #fff;
      .nav {
        span {
          margin: 0 13px;
          cursor: pointer;
        }
      }
      .personal {
        display: flex;
        align-items: center;
        i {
          cursor: pointer;
          display: inline-block;
          width: 20px;
          height: 20px;
          img {
            width: 100%;
            display: block;
          }
        }
        span.bar {
          display: inline-block;
          height: 20px;
          border: 1px solid #fff;
          margin: 0 10px;
        }
        button {
          margin-left: 20px;
          width: 65px;
          height: 30px;
          border: 1px solid #fff;
          background-color: rgba($color: #000000, $alpha: 0);
          color: #fff;
          border-radius: 15px;
          cursor: pointer;
        }
        .info {
          display: flex;
          span {
            font-size: 12px;
            line-height: 30px;
            margin: 0 10px 0 15px;
          }
          .header-img {
            position: relative;
            width: 33px;
            height: 33px;
            border-radius: 50%;
            overflow: hidden;
            background: #ececec;
            img {
              width: 100%;
              height: 100%;
              display: block;
            }
          }
          // .header-img::after {
          //   position: absolute;
          //   width: 100%;
          //   height: 100%;
          //   content: "";
          //   display: block;
          //   top: 0;
          //   left: 0;
          //   background: url("../assets/default.png") 0 0 no-repeat;
          //   background-size: 100%;
          // }
        }
      }
    }
  }
}
</style>
