<template>
  <div class="footer-bar">
    <ul>
      <li>
        <router-link to="/" :class="active == 0 ? 'active' : ''">
          <div class="icon">
            <img src="../assets/homeActive.png" alt="" v-if="active == 0" />
            <img src="../assets/home.png" alt="" v-else />
          </div>
          <div class="txt">首页</div>
        </router-link>
      </li>
      <li>
        <router-link to="/review" :class="active == 1 ? 'active' : ''">
          <div class="icon">
            <img src="../assets/reviewActive.png" alt="" v-if="active == 1" />
            <img src="../assets/review.png" alt="" v-else />
          </div>
          <div class="txt">回顾</div>
        </router-link>
      </li>
      <li>
        <router-link to="/live" :class="active == 2 ? 'active' : ''">
          <div class="icon">
            <img src="../assets/liveActive.png" alt="" v-if="active == 2" />
            <img src="../assets/live.png" alt="" v-else />
          </div>
          <div class="txt">直播</div>
        </router-link>
      </li>
      <li>
        <router-link to="/personal" :class="active == 3 ? 'active' : ''">
          <div class="icon">
            <img src="../assets/myCenterActive.png" alt="" v-if="active == 3" />
            <img src="../assets/myCenter.png" alt="" v-else />
          </div>
          <div class="txt">个人中心</div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
// @supports (bottom: constant(safe-area-inset-bottom)) or (bottom: env(safe-
//   area-inset-bottom)) {
//   .footer-bar {
//       padding-bottom: calc(constant(safe-area-inset-bottom) / 2);
//       padding-bottom: calc(0px + env(safe-area-inset-bottom) / 2);
//    }
// }
.footer-bar {
  position: fixed;
  width: 100%;
  height: 0.55rem;
  left: 0;
  bottom: 0;
  box-shadow: 0 0 20px -2px #e6e6e6;
  padding-bottom: calc(0 + env(safe-area-inset-bottom));
  background-color: #fff;
  ul {
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    background-color: #fff;
    margin: 0;
    height: 100%;
  }
  a {
    color: #999;
    display: block;
    text-decoration: none;
    i {
      font-size: 0.2rem;
    }
    img {
      display: inline-block;
      width: 0.2rem;
    }
    .txt {
      font-size: 0.12rem;
    }
  }
  .active {
    color: #1872bb;
  }
}
</style>
