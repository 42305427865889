<template>
  <div class="yl-select">
    <div class="icon">
      <slot></slot>
    </div>

    <div class="select" @click="optionsTrigger()" ref="select">
      <div class="placeholder" v-if="data == ''">{{ placeholder }}</div>
      {{ data }}
    </div>
    <div>
      <ul
        :class="['option', optionsShow ? 'active' : '']"
        v-if="optionsShow"
        ref="option"
      >
        <li
          @click="getValue"
          v-for="(item, i) in dataList"
          :key="i"
          onselectstart="return false;"
          :class="data.indexOf(item) != -1 ? 'active' : ''"
        >
          {{ item }}
          <i v-if="multiple && data.indexOf(item) != -1" class="yes">
            <img src="../assets/yes.png" alt="" />
          </i>
        </li>
      </ul>
    </div>
    <div class="right-icon">
      <img
        src="../assets/bottom.png"
        alt=""
        oncontextmenu="return false;"
        ondragstart="return false;"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    multiple: {
      type: Boolean,
      default: false,
    },
    dataList: {
      type: Array,
      require: true,
    },
    placeholder: String,
  },
  data() {
    return {
      optionsShow: false,
      data: this.value,
    };
  },
  watch: {
    value(newVal) {
      this.data = newVal;
    },
    data(newVal) {
      this.$emit("input", newVal);
    },
  },
  mounted() {
    let _this = this;
    document.addEventListener("click", function (e) {
      if (_this.$refs.option) {
        if (_this.$refs.select.contains(e.target)) {
          _this.optionsShow = true;
        } else if (!_this.$refs.option.contains(e.target)) {
          _this.optionsShow = false;
        }
      }
    });
  },
  methods: {
    optionsTrigger() {
      this.optionsShow = !this.optionsShow;
    },
    getValue(e) {
      if (this.multiple) {
        let temp = e.currentTarget.innerText.trim();
        let arr = this.data == "" ? [] : this.data.split(" | ");
        if (this.data.indexOf(temp) != -1) {
          //   当前已选择
          for (let i = 0; i < arr.length; i++) {
            if (arr[i] == temp) {
              arr.splice(i, 1);
            }
          }
        } else {
          //   未选择 把数据加进去
          arr.push(temp);
        }
        this.data = arr.join(" | ");
      } else {
        this.data = e.currentTarget.innerText.trim();
        this.optionsShow = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.yl-select {
  position: relative;
  height: 40px;
  margin-bottom: 10px;
  border: 1px solid #f8f5f1;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  font-size: 14px;
  .select {
    height: 100%;
    line-height: 40px;
    margin: 0 40px;
    white-space: nowrap;
    overflow-x: scroll;
    -ms-overflow-style: none; // IE 10+ 隐藏滚动条
    scrollbar-width: none; // Firefox 隐藏滚动条
  }
  .placeholder {
    color: #b1b1b1;
  }
  .option {
    position: absolute;
    top: 40px;
    z-index: 2022;
    width: 100%;
    list-style: none;
    margin: 5px 0 0;
    padding: 5px 0;
    max-height: 200px;
    // height: 0;
    border: 1px solid rgb(228, 231, 237);
    overflow-y: scroll;
    -ms-overflow-style: none; // IE 10+ 隐藏滚动条
    scrollbar-width: none; // Firefox 隐藏滚动条
    background-color: #fff;
    li {
      position: relative;
      height: 40px;
      line-height: 40px;
      padding: 0 40px;
      .yes {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 40px;
        height: 40px;
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 20px;
        }
      }
    }
    li:hover {
      background-color: rgb(245, 247, 250);
    }
    li.active {
      color: #409eff;
      font-weight: 600;
    }
  }
  .option::-webkit-scrollbar {
    width: 0 !important;
  }
  .select::-webkit-scrollbar {
    height: 0 !important; // Chrome Safari 隐藏滚动条
  }
  .icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 40px;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      max-height: 50%;
      max-width: 50%;
    }
  }
  .right-icon {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      max-height: 50%;
      max-width: 50%;
    }
  }
}
</style>
