<template>
  <div class="personal-pc">
    <pc-header :logout="true"></pc-header>
    <div class="main">
      <div class="left-box">
        <ul class="tab-bar">
          <li :class="num == 1 ? 'active' : ''" @click="num = 1">
            <span><img src="../../assets/info.png" alt="" /></span
            ><span>基本信息</span>
          </li>
          <li :class="num == 2 ? 'active' : ''" @click="changeTab(2)">
            <span><img src="../../assets/history.png" alt="" /></span
            ><span>浏览历史</span>
          </li>
          <li :class="num == 3 ? 'active' : ''" @click="changeTab(3)">
            <span><img src="../../assets/myAppointment.png" alt="" /></span
            ><span>我的预约</span>
          </li>
          <li :class="num == 4 ? 'active' : ''" @click="changeTab(4)">
            <span><img src="../../assets/myCollect.png" alt="" /></span
            ><span>我的收藏</span>
          </li>
          <li
            :class="num == 5 ? 'active' : ''"
            @click="getHavePassword(), changeTab(5)"
          >
            <span><img src="../../assets/updatePasswordPc.png" alt="" /></span
            ><span>修改密码</span>
          </li>
          <li :class="num == 6 ? 'active' : ''" @click="userLogout()">
            <span><img src="../../assets/logout.png" alt="" /></span
            ><span>退出登录</span>
          </li>
        </ul>
      </div>
      <div class="right-box" v-show="num == 1">
        <div class="info-header">基本信息</div>
        <div class="info-box">
          <div class="left-box">
            <div class="head-photo" @click="toLogin()">
              <img v-if="logining && wxheadimg" :src="wxheadimg" alt="" />
              <img v-else src="../../assets/default.png" alt="" />
              <!-- <router-link to="/login" replace>
                <img v-if="!logining" src="../../assets/default.png" alt=""
              /></router-link> -->
            </div>
          </div>
          <div class="right-box">
            <div class="personal-information">
              <div class="form">
                <yl-input v-model="formData.username" placeholder="请输入姓名"
                  ><img src="../../assets/name.png" alt=""
                /></yl-input>
                <yl-input
                  v-model="formData.phone"
                  placeholder="请输入手机号"
                  :disabled="true"
                  ><img src="../../assets/phone.png" alt=""
                /></yl-input>
                <yl-input
                  v-model="formData.organization"
                  placeholder="请输入工作单位"
                  ><img src="../../assets/company.png" alt=""
                /></yl-input>
                <yl-input
                  v-model="formData.email"
                  placeholder="请输入电子邮箱"
                  :disabled="true"
                  ><img src="../../assets/email.png" alt=""
                /></yl-input>
                <yl-select
                  v-model="formData.jobfunction"
                  :dataList="jobFunctionData"
                  placeholder="请选择您的工作职能"
                >
                  <img src="../../assets/job.png" alt="" />
                </yl-select>
                <yl-select
                  v-model="formData.interest"
                  :multiple="true"
                  :dataList="interestData"
                  placeholder="请选择感兴趣的领域"
                >
                  <img src="../../assets/interest.png" alt="" />
                </yl-select>
                <yl-select
                  v-model="formData.enquiry"
                  :dataList="enquiryData"
                  placeholder="您是否需要询价或联系销售代表？"
                >
                  <img src="../../assets/myAppointment.png" alt="" />
                </yl-select>
              </div>
              <button class="comfirm" @click="comfirmFn()">保存</button>
            </div>
          </div>
        </div>
      </div>
      <div class="content" v-show="num == 2">
        <div class="info-header mb">浏览历史</div>
        <div class="info-box">
          <div class="info-list">
            <span v-if="historyDataList.length == 0" class="dataNull"
              >暂无课程</span
            >
            <div
              v-else
              class="column"
              v-for="(item, index) in historyDataList"
              :key="index"
            >
              <div class="time" v-if="item.data.length > 0">
                {{ item.creationTime }}
              </div>
              <div class="column-item history">
                <pc-video-small
                  :show="false"
                  v-for="(childItem, i) in item.data"
                  :key="i"
                  :data="childItem"
                ></pc-video-small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content" v-show="num == 3" style="height: 805px">
        <div class="info-header mb">我的预约</div>
        <div class="info-box">
          <div class="info-list">
            <div class="column">
              <!-- <div class="time">2021-05-16</div> -->
              <div class="column-item history">
                <span v-if="appointmentDataList.length == 0" class="dataNull"
                  >暂无课程</span
                >
                <pc-video-small
                  v-else
                  v-for="(item, index) in appointmentDataList"
                  :key="index"
                  :data="item.liveMap"
                  :myApply="true"
                  @refDataList="getAppointmentList()"
                ></pc-video-small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content" v-show="num == 4" style="height: 805px">
        <div class="info-header mb">我的收藏</div>
        <div class="info-box">
          <div class="info-list collect-list">
            <span v-if="collectDataList.length == 0" class="dataNull"
              >暂无课程</span
            >
            <!-- <div class="column" v-for="index in 3" :key="index"> -->
            <!-- <div class="time">2021-05-16</div> -->
            <!-- <div class="column-item history"> -->
            <pc-video-small
              v-else
              v-for="(item, index) in collectDataList"
              :key="index"
              :data="item.liveMap"
              :myCollect="true"
              @refDataList="getCollectList()"
            ></pc-video-small>
            <!-- </div> -->
            <!-- </div> -->
          </div>
        </div>
      </div>

      <div class="right-box" v-show="num == 5" style="min-height: 437px">
        <div class="info-header">修改密码</div>
        <div class="info-box">
          <div class="form-two">
            <yl-input
              type="password"
              placeholder="请输入您的旧密码"
              v-model="modify.oldCode"
              style="margin-bottom: 38px"
            >
              <img src="../../assets/password.png" alt="" />
            </yl-input>
            <yl-input
              type="password"
              v-model="modify.password"
              placeholder="请输入您的新密码"
            >
              <img src="../../assets/password.png" alt="" />
            </yl-input>
            <yl-input
              type="password"
              v-model="modify.alignPassword"
              placeholder="请输入再次输入您的新密码"
            >
              <img src="../../assets/password.png" alt="" />
            </yl-input>
            <div class="modify-btn" @click="submitFn">确认修改</div>
          </div>
        </div>
      </div>
    </div>
    <pc-footer></pc-footer>
  </div>
</template>

<script>
import PcHeader from "../../components/pc-header.vue";
import PcFooter from "../../components/pc-footer.vue";
import YlInput from "../../components/yl-input.vue";
import YlSelect from "../../components/yl-select.vue";
import PcVideoSmall from "../../components/pc-video-small.vue";
import {
  userInfo,
  logout,
  updateUserInfo,
  updateUserPassword,
  historyList,
  appointmentList,
  havePassword,
  collectList,
} from "../../api/user";
import { removeToken } from "../../utils/auth";
export default {
  components: {
    PcHeader,
    PcFooter,
    YlInput,
    YlSelect,
    PcVideoSmall,
  },
  data() {
    return {
      num: 1,
      imgVisible: false,
      modify: {
        oldCode: null,
        password: null,
        alignPassword: null,
      },
      wxheadimg: "",
      formData: {
        id: null,
        username: "",
        phone: "",
        // password: "",
        organization: "",
        email: "",
        jobfunction: "",
        interest: "",
        enquiry: "",
        // sourceTag: "123",
        // registerType: "123",
        // registerCourse: "123",
        // wxOpenId: "123",
        // device: "123",
      },
      jobFunctionData: [
        "行政 - 其他",
        "行政 - 采购代理人",
        "生物信息学/信息技术人员",
        "临床医生",
        "主管",
        "实验室主任",
        "实验室人员",
        "非科研人员",
        "其他医学专业人员",
        "合作伙伴",
        "病理学家",
        "医师",
        "首席研究员",
        "教授",
        "学生",
      ],
      interestData: [
        "农业研究",
        "动物测试",
        "应用微生物学",
        "自体免疫性疾病研究",
        "癌症研究",
        "心血管疾病研究",
        "细胞与分子生物学研究",
        "临床肿瘤学",
        "消费者基因组学",
        "遗传与复杂疾病研究",
        "遗传健康",
        "代谢疾病研究",
        "微生物学/病毒学研究",
        "神经科学研究",
        "非侵入性产前检测",
        "植物检测",
        "孕前与生育保健",
        "公共卫生",
        "罕见及遗传性疾病",
      ],
      enquiryData: ["是的，我需要", "不需要，发送产品资料即可"],
      phoneReg: /^[1][3-9]\d{9}$|^([6|9])\d{7}$|^[0][9]\d{8}$|^6\d{5}$/,
      passwordReg: /^[a-zA-Z0-9]{6,12}$/,
      emailReg:
        /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/,
      historyDataList: [],
      appointmentDataList: [],
      collectDataList: [],
      logining: false,
    };
  },
  created() {
    this.getUserInfo();
    this.getHistoryList();
    this.getAppointmentList();
    this.getCollectList();
  },
  methods: {
    getHavePassword() {
      havePassword().then((res) => {
        if (res.code == 1244) {
          this.$router.replace({ path: "/retrieve" });
        }
      });
    },
    submitFn() {
      for (var key in this.modify) {
        if (!this.modify[key]) {
          this.$ylmessage.error("请输入您的旧密码与新密码");
          return;
        }
      }
      // else {
      // if (this.modify.password && this.modify.alignPassword) {
      if (this.modify.password != this.modify.alignPassword) {
        this.$ylmessage.error("两次密码不一致");
      } else if (
        !this.passwordReg.test(this.modify.oldCode) ||
        !this.passwordReg.test(this.modify.password) ||
        !this.passwordReg.test(this.modify.alignPassword)
      ) {
        this.$ylmessage.error("密码由6~12位字母数字组合");
        return;
      } else {
        // console.log("修改密码");
        updateUserPassword({
          id: this.formData.id,
          oldCode: this.modify.oldCode,
          password: this.modify.password,
        }).then((res) => {
          if (res.code == 0) {
            this.$ylmessage.success("密码修改成功");
            this.modify.oldCode = "";
            this.modify.password = "";
            this.modify.alignPassword = "";
          } else {
            this.$ylmessage.error(res.msg);
          }
        });
      }
      // }
      // }
    },
    // 退出登录
    userLogout() {
      if (this.logining) {
        logout({
          id: this.formData.id,
          token: null,
        }).then((res) => {
          if (res.code == 0) {
            // setToken(null);
            removeToken();
            // this.$router.replace({ path: "/home" });
            location.reload();
          }
        });
      }
    },
    // 查询用户信息
    getUserInfo() {
      // if (getToken()) {
      userInfo().then((res) => {
        if (res.code == 0) {
          this.wxheadimg = res.data.wxheadimg;
          this.formData.id = res.data.id;
          this.formData.username = res.data.username;
          this.formData.phone = res.data.phone;
          this.formData.organization = res.data.organization;
          this.formData.email = res.data.email;
          this.formData.jobfunction = res.data.jobfunction;
          this.formData.interest = res.data.interest;
          this.formData.enquiry = res.data.enquiry;

          this.logining = true;
        } else if (res.code == 60000) {
          this.logining = false;
          this.$router.push({ path: "/login" });
        }
      });
      // } else {
      //   this.logining = false;
      //   // this.$router.replace({ path: "/login" });
      // }
    },
    changeTab(num) {
      if (this.logining) {
        this.num = num;
      } else {
        this.$router.push({ path: "/login" });
      }
    },
    toLogin() {
      if (!this.logining) {
        this.$router.push({ path: "/login" });
      }
    },
    // 修改基本信息
    comfirmFn() {
      if (this.logining) {
        for (var key in this.formData) {
          if (this.formData[key] != "0" && !this.formData[key]) {
            this.$ylmessage.error("您的信息尚未填写，请补全信息");
            return;
          }
        }
        updateUserInfo(this.formData).then((res) => {
          if (res.code == 0) {
            this.$ylmessage.success("信息保存成功");
            // setTimeout(() => {
            //   this.$router.replace({ path: "/personal" });
            // }, 1500);
          }
        });
      } else {
        this.$router.push({ path: "/login" });
      }
    },
    // 历史记录
    getHistoryList() {
      historyList().then((res) => {
        if (res.code == 0) {
          this.historyDataList = res.list;
        }
      });
    },
    // 我的预约
    getAppointmentList() {
      appointmentList().then((res) => {
        if (res.code == 0) {
          this.appointmentDataList = res.list;
        }
      });
    },
    // 我的收藏
    getCollectList() {
      collectList().then((res) => {
        if (res.code == 0) {
          this.collectDataList = res.list;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.personal-pc {
  height: 100%;
  background: url("../../assets/pcbgsmall.png") 0 0 no-repeat;
  background-size: 100% auto;
}
.main {
  width: 1200px;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 100px;
  display: flex;
  justify-content: space-between;
  .tab-bar {
    background: #fff;
    width: 263px;
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      line-height: 60px;
      height: 60px;
      box-sizing: border-box;
      padding-left: 57px;
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        width: 21px;
        // margin-top: 3px;
        // display: inline;
        margin-right: 22px;
      }
    }
    li:hover {
      background: #e4e4e4;
    }
    .active {
      background: #e4e4e4;
    }
  }
  .right-box {
    width: 907px;
    min-height: 516px;
    background: #fff;
    .info-header {
      height: 57px;
      border-bottom: 1px solid #ececec;
      line-height: 57px;
      padding-left: 30px;
      box-sizing: border-box;
    }
    .info-box {
      display: flex;
      .left-box {
        width: 163px;
        margin-right: 50px;
        .head-photo {
          position: relative;
          width: 84px;
          height: 84px;
          border-radius: 50%;
          overflow: hidden;
          margin: 30px 0 0 80px;
          img {
            width: 100%;
            display: block;
          }
        }
        // .head-photo::after {
        //   position: absolute;
        //   width: 100%;
        //   height: 100%;
        //   content: "";
        //   display: block;
        //   top: 0;
        //   left: 0;
        //   background: url("../../assets/default.png") 0 0 no-repeat;
        //   background-size: 100%;
        // }
      }
      .right-box {
        width: 608px;
        height: auto;
        padding-top: 36px;
        box-sizing: border-box;
      }
      .form-two {
        width: 610px;
        margin: auto;
        padding-top: 58px;
        padding-bottom: 54px;
        .modify-btn {
          height: 40px;
          width: 284px;
          border-radius: 20px;
          background-color: #1772bb;
          text-align: center;
          line-height: 40px;
          color: #fff;
          margin: auto;
          margin-top: 34px;
          cursor: pointer;
        }
      }
    }
  }
  .content {
    width: 100%;
    background: #fff;
    width: 907px;
    height: 805px;
    overflow-y: scroll;
    .info-box {
      padding: 0 28px 38px;
      .info-list {
        // display: flex;
        // flex-wrap: wrap;
        .dataNull {
          display: inline-block;
          color: #aaa;
          margin-top: 200px;
          width: 100%;
          text-align: center;
        }
        .pc-video-small {
          width: 270px;
        }
        .pc-video-small:nth-child(3n-1) {
          margin: 0 20px;
        }
      }
      .collect-list {
        display: flex;
      }
    }
    .mb {
      margin-bottom: 26px;
    }
    .mb2 {
      margin-bottom: 14px;
    }
    .time {
      display: flex;
      justify-content: flex-end;
      // padding-right: 30px;
      font-size: 14px;
      margin-top: 12px;
      margin-bottom: 14px;
    }
    .column-item {
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;
    }

    .info-header {
      height: 57px;
      border-bottom: 1px solid #ececec;
      line-height: 57px;
      padding-left: 30px;
      width: 100%;
      box-sizing: border-box;
    }
  }
  .column:nth-of-type(1) .time {
    margin-top: 26px;
  }
  .content::-webkit-scrollbar {
    display: none;
  }
}
.personal-information {
  .comfirm {
    // margin-top: 0.2rem;
    cursor: pointer;
    margin-left: auto;
    width: 142px;
    height: 40px;
    display: block;
    font-size: 14px;
    border: none;
    border-radius: 20px;
    background: #49b67b;
    color: #fff;
    margin-top: 20px;
  }
}
</style>
